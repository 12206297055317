import React, { useRef, useState,useCallback,useEffect } from 'react';
import Header from '../../commoncomponents/header';
import Footer from '../../commoncomponents/footer';
import Select, { components } from 'react-select';
import WithToast from '../../commonjs/withtoast';
import axiosInstance from '../../commonjs/axiosinstand';
import { country_codeapi, forgotpasswordsendotpapi } from '../../commonjs/url';
import { handleApiError } from '../../commonjs/commonfunctions';
import { useNavigate } from 'react-router-dom';
import Forgototppage from './forgototppage';
import Forgotpasswordpage from './forgotpasswordpage';
const mcclistapiurl = country_codeapi();
const forgotpasswordsendotpapiurl = forgotpasswordsendotpapi();
function Forgotpage({setIsLoading4,showErrorToast}) {
    const [formValues,setFormValues] = useState({
        forgotvalueselect:'Email',forgotemail:'',forgotmobile:'',forgotmccid:'',countryOptions:[],isLoading:false,country_code:'',dial_code:'',
    });
    const [error422,setError422] = useState('');
    const navigate = useNavigate();
    const [showforgotpage,setShowforgotpage] = useState(true);
    const [showforgotpageotp,setShowforgotpageotp] = useState(false);
    const [showforgotpagepassword,setShowforgotpagepassword] = useState(false);
    const [isenabled,setEnabled] = useState(false);
    const mobileInputRef = useRef(null);
    const updateExampleNumber = useCallback((iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      }, []);
    const fetchCountryCode = useCallback(async () => {
        if (formValues.countryOptions.length > 0) return;
        setFormValues((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      
        try {
          const response = await axiosInstance.get(mcclistapiurl);
          const data = response.data;
      
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
      
            setFormValues((prevState) => ({
              ...prevState,
              countryOptions: codes,
            }));
            let code = 'US';
            const selectedCountryData = codes.find(
              (country) => country.country_code === code
            );
      
            if (selectedCountryData) {
              setFormValues((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
      
              const maxLength =  updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        } finally {
          setFormValues((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        }
    }, [formValues.countryOptions.length, updateExampleNumber, showErrorToast]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setError422('');
    };
    const responseforgotvalueclicemail = () => {
        setFormValues((prev)=>({
            ...prev,forgotvalueselect:'Email'
        }));
    }
    const responseforgotvalueclickmobile = () => {
        setFormValues((prev)=>({
            ...prev,forgotvalueselect:'Mobile'
        }));
        fetchCountryCode();
    }
    const customStyles2 = {
        control: (provided) => ({
          ...provided,
          width: '80px',
          height: '40px',
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          }),
          dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 8px',
          }),
          indicatorSeparator: () => ({
          display: 'none',
          }),
          singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          }),
          valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          }),
          input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          menu: (provided) => ({
          ...provided,
          minWidth: '300px',})  
      }
      const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* {data.label} */}
        </div>
    );
    const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
    };
    const handleCountryChange = (selectedOption) => {
        const selectedCountryData = formValues.countryOptions.find(
            (country) => country.value === selectedOption.value
          );
      
          if (selectedCountryData) {
              setFormValues((prevState) => ({
                  ...prevState,
                  mcc_id: selectedCountryData.mcc_id,
                  dial_code: `+${selectedCountryData.dial_code}`,
                  country_code: selectedCountryData.country_code,
                }));
      
            const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
            if (maxLength !== null && mobileInputRef.current) {
              mobileInputRef.current.setAttribute('maxlength', maxLength);
            }
          }
          setError422('');
    }
    const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
  
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
  
        const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
        if (numericPastedText.length > maxLength) {
            numericPastedText = numericPastedText.slice(0, maxLength);
        }
  
        setFormValues((prev)=>({
            ...prev,username:numericPastedText
        }));
        setError422('');
        e.preventDefault();
    }
    const check = useCallback(()=>{
        const select = formValues.forgotvalueselect === 'Email' || formValues.forgotvalueselect === 'Mobile';
        let values = false;
        if(formValues.forgotvalueselect === 'Email' && formValues.forgotemail){
            values = true;
        }else if(formValues.forgotvalueselect === 'Mobile' && formValues.forgotmobile){
            values = true;
        }else{
            values = false;
        }
        const valid = (
           select !== '' && values === true
        )
        setEnabled(valid);
    },[formValues.forgotvalueselect,formValues.forgotemail,formValues.forgotmobile]);
    useEffect(()=>{
    check();
    },[check]);
    const sendotp = async() => {
        let data ={};
        const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (formValues.forgotvalueselect === 'Email') {
            if(!gmailUsernamePattern.test(formValues.forgotemail)){
                setError422('Please Enter a valid email address');
                return false;
            }else{
                setError422('')
            }
            data = {
              username: formValues.forgotemail, // Send email if 'Email' is selected
            };
          } else if (formValues.forgotvalueselect === 'Mobile') {
            if(!/^\d+$/.test(formValues.forgotmobile)){
                setError422('Please Enter a valid Mobile Number');
                return false;
            }else{
                setError422('');
            }
            data = {
              username: formValues.forgotmobile, // Send mobile number if 'Mobile' is selected
              country_code: formValues.dial_code, // Include dial code for mobile
            };
          }
      try{
        setIsLoading4(true);
      const response = await axiosInstance.post(forgotpasswordsendotpapiurl,data);
      if(response.status === 200){
        setShowforgotpage(false);
        setShowforgotpageotp(true);
        setShowforgotpagepassword(false);
        setIsLoading4(false);
      }
      }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast)
      }
    }
    const cancel = () =>{
      setFormValues({
        ...formValues,
        forgotvalueselect:'', // Update the state with selected value
        forgotemail:'',
        forgotmobile:'',
        forgotmccid:''
      });
      navigate('/login');
    }
  return (
    <>
    <Header/>
    <div className="signin_bg">
            <div className="signin_wrapper signin_wrapper1">
                <div className="form-container form-container1">
                {showforgotpage &&(
                    <>
                    <h6 className='forgot_password_h6'>Forget your password</h6>
                    <div className='forgot_div'>
                          <button onClick={responseforgotvalueclicemail} className={`forgot_div_button forgot_div_button_right_radius ${formValues.forgotvalueselect === 'Email' ? 'forgot_div_button_active' : ''}`} >Email</button>  
                            <button onClick={responseforgotvalueclickmobile}  className={`forgot_div_button forgot_div_button_left_radius ${formValues.forgotvalueselect === 'Mobile' ? 'forgot_div_button_active ' : ''}`}>Mobile</button>
                    </div>
                    <p className='forget_p'>Enter your mobile number for the verification proccess, we will send verification code to your mobile number.</p>
                    <div className="forgot_row">
                    <div className="col col_1">    
                    <div className="form_group">
                    {formValues.forgotvalueselect === 'Email' ? (    
                    <>    
                    <label className='forgot_label'>Email</label>
                    <input type="text" name="forgotemail" className={`form_controls logininputsign ${formValues.email ? 'logininputsign-active' : '' }`}  value={formValues.email} onChange={handleChange}/>   
                    </>
                    ) : (
                    <>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <div style={{ position: 'absolute', left: 0,top:0,zIndex: 0 }}>
          <Select options={formValues.countryOptions} onChange={handleCountryChange} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={formValues.isLoading} styles={customStyles2}/>
          </div>
          <input type="text" name='forgotmobile' className={`form-controls logininputsign ${formValues.forgotmobile ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{ paddingLeft: `${80}px`, width: '100%' }} placeholder="Mobile Number" value={formValues.forgotmobile} onPaste={(e) => handlePaste(e)} onChange={handleChange}/>          
            </div>
                    </>
                    )}
                    </div>
                    </div>
                    {error422 && (<div className='errors'>{error422}</div>)} 
                    <div className="col col_1">
                     <div className='forgot_button_div'>
                        <button className='forgot_cancel' onClick={cancel}>Cancel</button>
                        <button className={`forgot_send_code ${isenabled ? 'forgot_send_code_active' : 'forgot_send_code_disabled'}`} disabled={!isenabled} onClick={sendotp} >Send Code</button>
                     </div>
                    </div>
                    </div>
                    </> 
                )} 
                {showforgotpageotp && (
                  <Forgototppage formValues={formValues} setShowforgotpage={setShowforgotpage} setShowforgotpageotp={setShowforgotpageotp} setShowforgotpagepassword={setShowforgotpagepassword} setIsLoading4={setIsLoading4} showErrorToast={showErrorToast} />  
                )}  
                {showforgotpagepassword && (
                   <Forgotpasswordpage setIsLoading4={setIsLoading4} showErrorToast={showErrorToast} /> 
                )}
                </div>
                </div>
                </div>
    <Footer/>
    </>
  )
}

export default WithToast(Forgotpage);