import React,{useEffect} from 'react'
import Header from './header'
import Footer from './footer'
import geroup from '../images/Group 11565.svg';
import appstore from '../images/app store-02.svg';
import playstore from '../images/app store-03.svg';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../commonjs/redux/action';
import WithToast from '../commonjs/withtoast';
function Playstore({showErrorToast}) {
    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.userProfile.userProfile);
    useEffect(() => {
        dispatch(fetchUserProfile(showErrorToast));
    }, [dispatch,showErrorToast]);
    return (
        <>
            <Header />
            <div className='playstore_appstore root_work'>
                <div className='c'>
                <div className='row ma_top_0'>
                <div className='col col_2'>
                <div className='playstore_appstore'>
                 <img src={geroup} alt='group' className='playsuccessimage'></img>
                 </div>   
                </div>
                <div className='col col_2'>
                <div className='playstore_appstore'>
                 <p className='playsuccessimagep'><span>Congratulations!</span> {userProfile.name} On logging into your account!</p>
                  <p className='playsuccessimagep'>Enjoy benefits by downloading
                  Our <span>ROOTS</span> app.</p>
                  <div className='col col_2'>
                    <p className='playsuccessimagep d_fle'><img src={playstore} alt='playstore' className='appstoreimage'></img>Play Store</p>
                   </div> 
                    <div className='col col_2'>  
                    <p className='playsuccessimagep d_fle'><img src={appstore} alt='appstore' className='appstoreimage'></img>App Store</p>
                  </div>
                 </div>   
                </div>
                </div>    
                </div>
                {/* <p>Hello Albert, congratulations on logging into your account!<br></br> Enjoy enhanced benefits by downloading our ROOTS app.</p>
                <div className='c'>
                    <div className='row ma_top_0'>
                        <div className='col col_2'>
                            <div className='playstore_appstore'>
                                <h2 className='tit_30 san_color_g text_center'>Play Store</h2>
                                <div className='playstore_appstore_btn text_center'>
                                    <Link to='' class="btn">user</Link>
                                    <Link to="" class="btn mar_left">provider</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col col_2'>
                            <div className='playstore_appstore'>
                                <h2 className='tit_30 san_color_g text_center'>App Store</h2>
                                <div className='playstore_appstore_btn text_center'>
                                    <Link to="" class="btn">user</Link>
                                    <Link to="" class="btn mar_left">provider</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <Footer />
        </>
    )
}

export default WithToast(Playstore)