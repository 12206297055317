import React,{useState,useRef, useEffect,useCallback} from "react";
// import fbIcon from '../images/fb.svg';
import { useLocation } from "react-router-dom";
// import googleIcon from '../images/google.svg';
// import { Link } from "react-router-dom";
import Select, { components } from 'react-select';
import blueeye from '../images/Icon.svg';
import blueeyeslash from '../images/Icon (1).svg'
import Header from "../commoncomponents/header";
import Footer from "../commoncomponents/footer";
import axiosInstance from "../commonjs/axiosinstand";
import { countryselectapi,country_codeapi,signupapi,resendotpapi,signupverifyapi} from "../commonjs/url";
import { handleApiError,getMaskedEmail, process_params,} from "../commonjs/commonfunctions";
import OtpInput from 'react-otp-input';
import WithToast from "../commonjs/withtoast";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Loginpage from "./loginpage";
const countryselectapiurl = countryselectapi();
const resendOTPapiurl = resendotpapi();
const country_codeapiurl = country_codeapi();
const signupapiurl = signupapi();
const signupverifyapiurl = signupverifyapi();
function Login({showErrorToast}){
      const [isSignUp, setIsSignUp] = useState(true);
      const [signupactive,setSignupactive] = useState(false);
      const navigate = useNavigate();
    //   const [countries, setCountries] = useState([]);
      const [formValues, setFormValues] = useState({
        name: '',
        country: null,
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        mcc_id:'',
        dial_code:'',
        country_code:'',
        countryOptions:[],
        countries:[],
        countryloading:false,
        isLoading3:false,
        passwordshow:false,confirmpasswordshow:false,
        countdown:120,
        otp:''
      });
      const passwordvisiblity = () => {
        setFormValues((prev)=>({
            ...prev,passwordshow:!prev.passwordshow
        }))
    }
    const confirmpasswordvisiblity = () => {
        setFormValues((prev)=>({
            ...prev,confirmpasswordshow:!prev.confirmpasswordshow
        }))
    }
      const location = useLocation();
      const [error,setError] = useState({
        nameerror:'',emailerror:'',passworderror:'',confirmPassworderror:'',
      });
      const [enabledverify,setEnabledverify] = useState(false);
      const [error422,setError422] = useState('');
      const [show1,setShow1]=useState(false);
      const [isLoading4,setIsLoading4] = useState(false);
      const mobileInputRef = useRef(null)
      const [otp, setOtp] = useState('');
      const updateExampleNumber = (iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            const maxLength = cleanedNumber.length;
            return maxLength;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      };
      const fetchCountryCode1 = useCallback(async () => {
        setFormValues((prevState)=>({
            ...prevState,
            isLoading3:true
        }));
        try {
            const response = await axiosInstance.get(country_codeapiurl);
            const data = response.data;
            if (data.message === 'Success') {
                const mccList = data.data;
                const codes = mccList.map((mcc) => ({
                    country_code: mcc.country_code,
                    dial_code: mcc.code,
                    mcc_id: mcc.id,
                    label: `${mcc.name} (+${mcc.code})`,
                    value: mcc.country_code,
                    flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
                }));
                setFormValues((prevState)=>({
                    ...prevState,
                    countryOptions:codes
                }))
                const selectedCountryData = codes.find(
                    (country) => country.country_code === 'US'
                );
                if (selectedCountryData) {
                    setFormValues((prevState)=>({
                        ...prevState,mcc_id :selectedCountryData.mcc_id
                    }));
                    setFormValues((prevState)=>({
                        ...prevState,dial_code :`+${selectedCountryData.dial_code}`
                    }));
                    setFormValues((prevState)=>({
                        ...prevState,country_code :selectedCountryData.country_code
                    }));  
                    // setMccId(selectedCountryData.mcc_id);
                    // setDialCode(`+${selectedCountryData.dial_code}`);
                    // setCountryCode(selectedCountryData.country_code);
                    // setCountryCodesign(selectedCountryData.country_code);
                    const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
                    if (maxLength !== null && mobileInputRef.current) {
                        mobileInputRef.current.setAttribute('maxlength', maxLength);
                    }
                }
            } else {
                console.error('Failed to fetch country codes');
            }
        } catch (error) {
          handleApiError(error,null,null,true,showErrorToast);
            setFormValues((prevState)=>({
                ...prevState,
                isLoading3:false
            }));
        } finally {
            setFormValues((prevState)=>({
                ...prevState,
                isLoading3:false
            })); // Set loading to false after the API call completes
        }
      },[showErrorToast,mobileInputRef]);
      const countryList = useCallback(async () => {
        try{
            setFormValues((prevState)=>({
                ...prevState,
                countryloading:true
            }));
          const response = await axiosInstance.get(countryselectapiurl);
          const data = response.data;
          if (data.message === 'Success') {
            const countryList = data.data;
            setFormValues((prevState)=>({
                ...prevState,
                countries:countryList.map(company => ({ value: company.id, label: company.name }))
            }))
            // setCountries(countryList.map(company => ({ value: company.id, label: company.name })));
            setFormValues((prevState)=>({
                ...prevState,
                countryloading:false
            }));
          }
        }catch(error){
            setFormValues((prevState)=>({
                ...prevState,
                countryloading:false
            }));
          handleApiError(error,null,null,true,showErrorToast);
        }
      },[showErrorToast])
      useEffect(() => {
        const fetchData = async () => {
            // First make the AJAX call
            // await makeAjaxCall();
             fetchCountryCode1();
             countryList();
        };
    
        fetchData();
    }, [fetchCountryCode1,countryList]);
    const handleToggle = (type) => {
      if (type === 'login') {
        setIsSignUp(false);
      } else if (type === 'signup') {
        setIsSignUp(true);
        fetchCountryCode1();
        countryList();
        setError((prev)=>({
          ...prev, nameerror:'',emailerror:'',passworderror:'',confirmPassworderror:'',
        }));
        setFormValues((prev)=>({
          ...prev,name: '',
          country: null,
          email: '',
          mobile: '',
          password: '',
          confirmPassword: '',
          mcc_id:'',
          dial_code:'',
          country_code:'',
          countryOptions:[],
          countries:[],
        }))
        setError422('');
      }
    };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setError422('');
        if (name === 'email') {
            setError((prevState) => ({
                ...prevState,
                emailerror: '',
            }));
        }
        if (name === 'password'){
            setError((prevState) => ({
                ...prevState,
                passworderror: '',
            }));
        }
        if (name === 'confirmPassword'){
            setError((prevState) => ({
                ...prevState,
                confirmPassworderror: '',
            }));
        }
    
      };
    
      const handleCountryChange = (selectedOption) => {
        setFormValues((prevState) => ({
          ...prevState,
          country: selectedOption,
        }));
        setError422('');
      };
      useEffect(()=>{
        if(location.pathname === '/signup'){
          setIsSignUp(true);
        }else{
          setIsSignUp(false);
        }
      },[location.pathname]);
      
      const handleKeyPresssign = (e) => {
        // Allow only numeric characters (0-9) and some specific control keys
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    
        if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
          e.preventDefault();
        }
      };
      const handlePhoneNumberChangesign = (value) => {
        setError422('')
        if (value.startsWith('0')) {
          value = value.slice(1);
        }
        const maxLength = mobileInputRef.current.getAttribute('maxlength');
        if (value.length > maxLength) {
          value = value.slice(0, maxLength);
        }
        // setMobileChangesign(value);
        setFormValues((prevState)=>({
            ...prevState,
            mobile:value
        }))
        // const fullNumber = `${dialCode}${value}`;
        // if (fullNumber.length <= maxLength + dialCode.length && isValidNumber(fullNumber)) {
        //   setIsValidPhoneNumber(true);
        // } else {
        //   setIsValidPhoneNumber(false);
        // }
        setError422('')
      };
      const handleCountryChangesign = (selectedOption) => {
        const selectedCountryData = formValues.countryOptions.find(
          (country) => country.value === selectedOption.value
        );
    
        if (selectedCountryData) {
            setFormValues((prevState)=>({
                ...prevState,mcc_id :selectedCountryData.mcc_id
            }));
            setFormValues((prevState)=>({
                ...prevState,dial_code :`+${selectedCountryData.dial_code}`
            }));
            setFormValues((prevState)=>({
                ...prevState,country_code :selectedCountryData.country_code
            }));
        //   setMccId(selectedCountryData.mcc_id);
        //   setDialCode(`+${selectedCountryData.dial_code}`);
        //   setCountryCodesign(selectedCountryData.country_code);
    
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
        setError422('');
      };
      const handlePastesign = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
  
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
  
        const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
        if (numericPastedText.length > maxLength) {
            numericPastedText = numericPastedText.slice(0, maxLength);
        }
  
        // setMobileChangesign(numericPastedText);
        setFormValues((prevState)=>({
            ...prevState,mobile:numericPastedText
        }))
        setError422('');
        e.preventDefault();
      };
      const customStyles2 = {
        control: (provided) => ({
          ...provided,
          width:formValues.isLoading3 ? '80px' : '80px',
          height: '40px',
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          }),
          dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 8px',
          }),
          indicatorSeparator: () => ({
          display: 'none',
          }),
          singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          }),
          valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          }),
          input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          menu: (provided) => ({
          ...provided,
          minWidth: '300px',})  
      }
      const customStyles1 = (hasValue) => ({
        control: (provided,state) => ({
          ...provided,
          borderColor: hasValue ? 'black' : '#DCDCDC',
          height:'40px',
          fontSize:'14px',
          borderRadius:'10px',
          boxShadow: state.isFocused ? 'none' : 'none',
          '&:hover': {
            borderColor: hasValue ? 'black' : '#DCDCDC',
          },
        }),placeholder: (provided) => ({
          ...provided,
          // color: '#ABABAB',
          width: '100%',
          fontSize:'12px',
          fontWeight:'500',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: hasValue ? 'black' : '#DCDCDC',
          svg: {
            fill: hasValue ? 'black' : '#B9B9B9',
          },
        }),
        indicatorSeparator: () => ({
          display: 'none',
        }),
          container:(provided) => ({
            ...provided,
            marginBottom:'20px'
          })
      });
      const customSingleValue1 = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* <p className="customsinglep">{`+${data.dial_code}`}</p> */}
        </div>
      );
      const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
      };
    const handleKeyPress1 = (e) => {
        const char = String.fromCharCode(e.which);
        if (!/^[a-zA-Z ]$/.test(char)) {
          e.preventDefault();
          setError((prevState) => ({
            ...prevState,
            nameerror: 'Only alphabetic characters and spaces are allowed',
          }));
          // setNameError('Only alphabetic characters and spaces are allowed.');
        } else {
          setError((prevState) => ({
            ...prevState,
            nameerror: '',
          }));
          // setNameError('');
        }
      }; 
      const checksign = useCallback(() => {
        const valid = (
          formValues.name !== '' &&
          formValues.email !== '' &&
          formValues.mobile !== '' &&
          formValues.country !== '' &&
          formValues.password !== '' &&
          formValues.confirmPassword !== ''
        );
        setSignupactive(valid);
      }, [formValues]);
    
      useEffect(() => {
        checksign();
      }, [checksign]);
    const signup = async (e) => {
        e.preventDefault();
        const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if(!gmailUsernamePattern.test(formValues.email)){
            setError((prevState) => ({
            ...prevState,
            emailerror: 'Invalid Email Address',
            }));
            return;
        }else{
            setError((prevState) => ({
            ...prevState,
            emailerror: '',
            }));
        }
        const lowercaseLetters = /[a-z]/g;
        const uppercaseLetters = /[A-Z]/g;
        const specialCharacters = /[!@#$%^&*()_+{}[\]:;<>,.?~/\-|=]/g;
        const numbers = /[0-9]/g;
        const minLength = 8;
        if (!lowercaseLetters.test(formValues.password)) {
            setError((prevState)=>({
              ...prevState,passworderror:'Password must contain at least one lowercase letter'
            }))
            return false;
        }else{
          setError((prevState)=>({
            ...prevState,
            passworderror : ''
          }));
        }if (!uppercaseLetters.test(formValues.password)) {
          setError((prevState)=>({
            ...prevState,passworderror:'Password must contain at least one uppercase letter'
          }))
            return false;
        }else{
            setError((prevState)=>({
              ...prevState,
              passworderror : ''
            }));
          }  if (!specialCharacters.test(formValues.password)) {
            setError((prevState)=>({
              ...prevState,passworderror:'Password must contain at least one special character'
            }))
            return false;
        } else{
            setError((prevState)=>({
              ...prevState,
              passworderror : ''
            }));
          } if (formValues.password.length < minLength) {
            setError((prevState)=>({
              ...prevState,passworderror:`Password must be at least ${minLength} characters long`
            }))
            return false;
        } else{
            setError((prevState)=>({
              ...prevState,
              passworderror : ''
            }));
          } if (!numbers.test(formValues.password)) {
            setError((prevState)=>({
              ...prevState,passworderror:`Password must contain at least one numeric character`
            }))
            return false;
        }  else{
            setError((prevState)=>({
              ...prevState,
              passworderror : ''
            }));
          }  
        if (formValues.password !== formValues.confirmPassword) {
            setError((prevState) => ({
              ...prevState,
              confirmPassworderror: 'Passwords do not match',
            }));
            return false;
           }else{
            setError((prevState) => ({
              ...prevState,
              confirmPassworderror: '',
            }));
        }
        const data = {
                name: formValues.name,
                country_id: formValues.country.value,
                email: formValues.email,
                mobile: formValues.mobile,
                password: formValues.password,
                mcc_id: formValues.mcc_id,
                confirmpassword: formValues.confirmPassword,
                verify:'email'
        }
        try {
          setIsLoading4(true);
            const response = await axiosInstance.post(signupapiurl,data);
            if(response.status === 200){
                setShow1(true);
                setIsLoading4(false);
                // startCountdown();
               }
        } catch (error) {
            handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
        }
    };
    const handleInputChangeotp = (event) => {
      // Allow only numeric characters
      const isNumber = /^\d*$/.test(event.key);

      if (!isNumber) {
          event.preventDefault();
      }
    };
    const handlePasteotp = (event) => {
      // Handle pasted text and allow only numeric characters
      const pastedText = event.clipboardData.getData('text/plain');
      const isNumeric = /^\d*$/.test(pastedText);

      if (!isNumeric) {
          event.preventDefault();
      } else {
          // Update the state with the pasted numeric characters
          setOtp(pastedText);
      }
  };
  const checkotp = useCallback(() => {
    const valid = (
      otp.length === 6 
    );
    setEnabledverify(valid);
  }, [otp]);
  useEffect(()=>{
    checkotp();
  },[checkotp])
  const startCountdown = () => {
    let seconds = 10;
    const intervalId = setInterval(() => {
        if (seconds > 0) {
            seconds--;
            setFormValues((prevState)=>({
              ...prevState,
              countdown:seconds
            }))
        } else {
            clearInterval(intervalId);
        }
    }, 1000);
  }
  const resendotp = async () => {
    const data = {
     verify : 'email'
    }
   try {
       setIsLoading4(true);
       const response = await axiosInstance.post(resendOTPapiurl, data);
       if (response.status === 200) {
           setIsLoading4(false);
           setOtp('');
           startCountdown();
       }
   }
   catch (error) {
     handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
   }finally{
     setIsLoading4(false);
   }
  }
  const signupverify = async() => {
    const data = {
      otp : otp,
      device_type:'web'
    }
     try{
      setIsLoading4(true);
        const response = await axiosInstance.post(signupverifyapiurl,data);
        if(response.status === 200){
          // setShow1(false)
          // setShow(true);
          // let X_AuthUser = response.data.data.authuser;
                  // const redirectUrl = getQueryParameter('redirecturl');
                   const params = process_params();
                      if (params.redirecturl) {
                        window.location.href = params.redirecturl
                          // redirectToWithAuthUser(redirectUrl, X_AuthUser);
                  } else {
                      // makeAjaxCall();
                      navigate(`/success`);
                  }
                  setIsLoading4(false);
        }
     }catch(error){
      handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
     }finally{
      setIsLoading4(false);
     }
   }
    return(
        <>
        {isLoading4 && (
                <>   
                    <div className="loaderover">
                    <div className="loader ">
                    </div>
                    </div>
                </>
            )} 
        <Header/>
         <div className="signin_bg">
            <div className="signin_wrapper">
                <div className="form-container">
                    <div className="slide-controls">
                    <input 
              type="radio" 
              name="slide" 
              id="login" 
              checked={!isSignUp} 
              onChange={() => handleToggle('login')} 
            />
            <input 
              type="radio" 
              name="slide" 
              id="signup" 
              checked={isSignUp} 
              onChange={() => handleToggle('signup')} 
            />
            <label htmlFor="signup" className="slide signup" onClick={() => handleToggle('signup')}>
              Signup
            </label>
            <label htmlFor="login" className="slide login" onClick={() => handleToggle('login')}>
              Login
            </label>

                        <div className="slider-tab"></div>
                    </div>
                    <div className="form-inner">
                        {isSignUp ? (
                            <form action="#" className="signup">
                                {/* <h3 className="tit_24">Sign up</h3>
                                <div>
                                    <Link to='' className="form_fb">
                                        <img src={fbIcon} alt="Facebook" />
                                        <p>Sign up with Facebook</p>
                                    </Link>
                                    <Link to='' className="form_google">
                                        <img src={googleIcon} alt="Google" />
                                        <p>Sign up with Google</p>
                                    </Link>
                                </div>
                                <div className="form_line">
                                    <p className="line_"></p>
                                    <p className="line_or">OR</p>
                                    <p className="line_"></p>
                                </div> */}
                                <div>
                                    <h3 className="tit_24">Create an account</h3>
                                </div>
                                <div className="row">
                                <div className="col col_1">
                                        <div className="form_group">
                                            <label>Name</label>
                                            <input type="text" name="name" className={`form_controls logininputsign ${formValues.name ? 'logininputsign-active' : '' }`} onKeyPress={handleKeyPress1} value={formValues.name} onChange={handleInputChange}/>
                                            {error.nameerror && <div className="errors">{error.nameerror}</div>}
                                        </div>
                                    </div>
                                    <div className="col col_1">
                                        <div className="form_group">
                                            <label>Country</label>
                                            <Select  options={formValues.countries}  value={formValues.country} isLoading={formValues.countryloading}  isSearchable={true}  onChange={handleCountryChange} styles={customStyles1(Boolean(formValues.country))} />
                                        </div>
                                    </div>
                                    <div className="col col_1">
                                        <div className="form_group">
                                            <label>Email address</label>
                                            <input type="email" name="email" value={formValues.email} onChange={handleInputChange} className={`form_controls logininputsign ${formValues.email ? 'logininputsign-active' : '' }`} />
                                            {error.emailerror && <div className="errors">{error.emailerror}</div>}
                                        </div>
                                    </div>
                                    <div className="col col_1">
                                        <div className="form_group">
                                            <label>Mobile Number</label>
                                            <div style={{ position: 'relative',}}>
                                            <div style={{ position: 'absolute', left: 0,top:'5px'}}>
                                            <Select options={formValues.countryOptions} onChange={handleCountryChangesign} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue1, Option: customOption }} placeholder="" isLoading={formValues.isLoading3} styles={customStyles2}/>
                                            </div>
                                            <input type="text" className={`form-controls logininputsign ${formValues.mobile ? 'logininputsign-active' : ''}`} id="mobile" ref={mobileInputRef} style={{width: '100%',paddingLeft:'80px'}}  value={formValues.mobile} name="mobile" onPaste={(e) => handlePastesign(e)} onKeyPress={handleKeyPresssign} onChange={(e) => handlePhoneNumberChangesign(e.target.value)}/>
                                            <input type="hidden" name="mcc_id" className="form-controls" placeholder="Mobile Number" value={formValues.mcc_id} onChange={handleInputChange}/>  
                                            </div>
                                        </div>
                                    </div>        
                                    <div className="col col_1">
                                        <div className="form_group" style={{position:'relative'}}>
                                            <label>Password</label>
                                            <input type={formValues.passwordshow ? 'text' : 'password'} name="password" value={formValues.password} onChange={handleInputChange} className={`form_controls logininputsign ${formValues.password ? 'logininputsign-active' : '' }`}/>
                                            <p className="toggle-passwordss" onClick={passwordvisiblity} >
                                              {formValues.passwordshow ? (
                                                  <img src={blueeye} className='eye' style={{ width: '18px', height: 'auto' }} alt="Hide Password" />
                                              ) : (
                                                  <img src={blueeyeslash} className='eye' style={{ width: '18px', height: 'auto' }} alt="Show Password" />
                                              )}
                                              </p>
                                            {error.passworderror && <div className="errors">{error.passworderror}</div>}
                                        </div>
                                    </div>
                                    <div className="col col_1">
                                        <div className="form_group" style={{position:'relative'}}>
                                            <label>Confirm Password</label>
                                            <input type={formValues.confirmpasswordshow ? 'text' : 'password'} name="confirmPassword" value={formValues.confirmPassword} onChange={handleInputChange} className={`form_controls logininputsign ${formValues.confirmPassword ? 'logininputsign-active' : '' }`} />
                                            <p className="toggle-passwordss" onClick={confirmpasswordvisiblity} >
                                              {formValues.confirmpasswordshow ? (
                                                  <img src={blueeye} className='eye' style={{ width: '18px', height: 'auto' }} alt="Hide Password" />
                                              ) : (
                                                  <img src={blueeyeslash} className='eye' style={{ width: '18px', height: 'auto' }} alt="Show Password" />
                                              )}
                                              </p>
                                            {error.confirmPassworderror && <div className="errors">{error.confirmPassworderror}</div>}
                                        </div>
                                    </div>
                                    
                                    <div className="col col_1">
                                    {error422 && <div className="errors">{error422}</div>}
                                        <button className={signupactive ? 'signin_btn-active' : 'signin_btn'} style={{marginTop : error422 ? '0px' : '20px'}} disabled={!signupactive} onClick={signup}>Sign up</button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                          <Loginpage setIsLoading4={setIsLoading4} setIsSignUp={setIsSignUp}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
       <Modal show={show1} centered>
        <Modal.Body style={{ margin: '0', padding: '0' }}>
            <div className='d-flex justify-content-center flex-column align-items-center modal-flex'>
            <h6 className='resetpassh6'>Enter Your OTP</h6>
            <p className='resetpassp resetpassp1'>Enter the 6-didit code sent<br></br>
            to {getMaskedEmail(formValues.email)}<br></br>
            Did not receive OTP ?<strong className={`${formValues.countdown > 0 ? 'inactiveotpsssp' : 'activeotpsssp'}`} style={{ cursor: formValues.countdown > 0 ? 'not-allowed' : 'pointer' }} onClick={formValues.countdown <= 0 ? resendotp : null}>Resend OTP</strong></p>
            <OtpInput value={otp} onChange={setOtp} numInputs={6} renderInput={(props,index) => <input {...props} onPaste={handlePasteotp} className={`custom-inputotp`} style={{border: otp[index] ? '1px solid black' : ''}} onKeyPress={handleInputChangeotp} />}/>
            <button className='resendotp mt-4'> {formValues.countdown > 0 ? `${formValues.countdown}S` : `${formValues.countdown}S`}</button>
            {error422 && (<div className='errors'>{error422}</div>)}
            <button style={{backgroundColor:enabledverify ? 'black' : '#EAEAEA',color:enabledverify ? 'white' : 'hsl(0, 0%, 20%)',cursor:enabledverify ? 'pointer' : 'not-allowed'}} disabled={!enabledverify} onClick={signupverify} className="loginsubmit mt-3">Verify</button>
            </div>
        </Modal.Body>
        </Modal>
        </>
    )
}
export default WithToast(Login);