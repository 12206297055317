import React, { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotresetpasswordapi } from '../../commonjs/url';
import axiosInstance from '../../commonjs/axiosinstand';
import blueeye from '../../images/Icon.svg';
import blueeyeslash from '../../images/Icon (1).svg';
import { handleApiError } from '../../commonjs/commonfunctions';
const forgotresetpasswordapiurl = forgotresetpasswordapi();
const Forgotpasswordpage = memo(({setIsLoading4,showErrorToast}) => {
    const navigate = useNavigate(); 
  const [formValues,setFormValues] = useState({
    password:'',confirmpassword:'',passwordshow:false,confirmpasswordshow:false
  });
  const [formValuesError,setFormValuesError] = useState({
    passworderror:'',confirmpassworderror:''
  });
  const [isenabled,setEnabled] = useState(false);
  const [error422,setError422] = useState('');
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    if(name === 'password'){
        setFormValuesError((prev)=>({
            ...prev,passworderror:''
        }));
    }
    if(name === 'confirmpassword'){
        setFormValuesError((prev)=>({
            ...prev,confirmpassworderror:''
        }));
    }
    setError422('');
};
const passwordvisiblity = () => {
    setFormValues((prev)=>({
        ...prev,passwordshow:!prev.passwordshow
    }))
}
const confirmpasswordvisiblity = () => {
    setFormValues((prev)=>({
        ...prev,confirmpasswordshow:!prev.confirmpasswordshow
    }))
}
const check = useCallback(()=>{
  const valid = (
    formValues.password !== '' && formValues.confirmpassword !==''
  )
  setEnabled(valid);
},[formValues.password,formValues.confirmpassword]);
useEffect(()=>{
    check();
},[check]);
const cancel = () => {
   navigate('/login');
}
const save = async() => {
    const lowercaseLetters = /[a-z]/g;
    const uppercaseLetters = /[A-Z]/g;
    const specialCharacters = /[!@#$%^&*()_+{}[\]:;<>,.?~/\-|=]/g;
    const numbers = /[0-9]/g;
    const minLength = 8;
    if (!lowercaseLetters.test(formValues.password)) {
        setFormValuesError((prevState)=>({
          ...prevState,passworderror:'Password must contain at least one lowercase letter'
        }))
        return false;
    }else{
        setFormValuesError((prevState)=>({
        ...prevState,
        passworderror : ''
      }));
    }if (!uppercaseLetters.test(formValues.password)) {
        setFormValuesError((prevState)=>({
        ...prevState,passworderror:'Password must contain at least one uppercase letter'
      }))
        return false;
    }else{
        setFormValuesError((prevState)=>({
          ...prevState,
          passworderror : ''
        }));
      }  if (!specialCharacters.test(formValues.password)) {
        setFormValuesError((prevState)=>({
          ...prevState,passworderror:'Password must contain at least one special character'
        }))
        return false;
    } else{
        setFormValuesError((prevState)=>({
          ...prevState,
          passworderror : ''
        }));
      } if (formValues.password.length < minLength) {
        setFormValuesError((prevState)=>({
          ...prevState,passworderror:`Password must be at least ${minLength} characters long`
        }))
        return false;
    } else{
        setFormValuesError((prevState)=>({
          ...prevState,
          passworderror : ''
        }));
      } if (!numbers.test(formValues.password)) {
        setFormValuesError((prevState)=>({
          ...prevState,passworderror:`Password must contain at least one numeric character`
        }))
        return false;
    }  else{
        setFormValuesError((prevState)=>({
          ...prevState,
          passworderror : ''
        }));
      }  
    if (formValues.password !== formValues.confirmpassword) {
        setFormValuesError((prevState) => ({
          ...prevState,
          confirmpassworderror: 'Passwords do not match',
        }));
        return false;
       }else{
        setFormValuesError((prevState) => ({
          ...prevState,
          confirmPassworderror: '',
        }));
    }
    const data = {
        password : formValues.password
    }
    try{
        const response = await axiosInstance.post(forgotresetpasswordapiurl,data);
        if(response.status === 200){
           navigate('/login');
        }
    }catch(error){
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
    }

}
  return (
    <>
     <h6 className='forgot_password_h6'>New password</h6>
     <p className='forget_p'>Set the new password for your account so you can loginto your account.</p>
     <div className="forgot_row">
    <div className="col col_1">    
    <div className="form_group" style={{position:'relative'}}>
    <label className='forgot_label'>Enter New Password</label>
    <input type={formValues.passwordshow ? 'text' : 'password'} name="password" className={`form_controls logininputsign ${formValues.password ? 'logininputsign-active' : '' }`}  value={formValues.password} onChange={handleChange}/>
    <p className="toggle-passwordss" onClick={passwordvisiblity} >
        {formValues.passwordshow ? (
            <img src={blueeye} className='eye' style={{ width: '18px', height: 'auto' }} alt="Hide Password" />
        ) : (
            <img src={blueeyeslash} className='eye' style={{ width: '18px', height: 'auto' }} alt="Show Password" />
        )}
       </p>
    {formValuesError.passworderror && <div className="errors">{formValuesError.passworderror}</div>}   
        </div>
        </div>
        <div className="col col_1">    
    <div className="form_group" style={{position:'relative'}}>
    <label className='forgot_label'>Confirm Password</label>
    <input type={formValues.confirmpasswordshow ? 'text' : 'password'} name="confirmpassword" className={`form_controls logininputsign ${formValues.confirmpassword ? 'logininputsign-active' : '' }`}  value={formValues.confirmpassword} onChange={handleChange}/> 
    <p className="toggle-passwordss" onClick={confirmpasswordvisiblity} >
        {formValues.confirmpasswordshow ? (
            <img src={blueeye} className='eye' style={{ width: '18px', height: 'auto' }} alt="Hide Password" />
        ) : (
            <img src={blueeyeslash} className='eye' style={{ width: '18px', height: 'auto' }} alt="Show Password" />
        )}
       </p>
    {formValuesError.confirmpassworderror && <div className="errors">{formValuesError.confirmpassworderror}</div>}  
        </div>
        </div>
        {error422 && (<div className='errors'>{error422}</div>)}  
        <div className="col col_1">
    <div className='forgot_button_div'>
    <button className='forgot_cancel' onClick={cancel}>Cancel</button>
    <button className={`forgot_send_code ${isenabled ? 'forgot_send_code_active' : 'forgot_send_code_disabled'}`} disabled={!isenabled} onClick={save} >Update</button>
    </div>
    </div>
        </div>   
    </>
  )
})

export default Forgotpasswordpage