import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg';
import { devandliveurl } from '../commonjs/url';
const devurl = devandliveurl();
function Footer() {
  return (
    <footer className="footer">
      <div className="c">
        <div className="row align_center border_bottom">
          <div className="col col_2">
            <Link to="/" className="logo">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="col col_2">
            <div className="social_icon">
            <Link to="https://www.facebook.com/Rootsappofficial?mibextid=LQQJ4d&rdid=dKoIU1vrXLQ1XiP3&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F79sNCwziBigW9Z8T%2F%3Fmibextid%3DLQQJ4d" 
  onClick={(e) => {
      e.preventDefault(); // Prevent normal navigation
      window.open("https://www.facebook.com/Rootsappofficial?mibextid=LQQJ4d&rdid=dKoIU1vrXLQ1XiP3&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F79sNCwziBigW9Z8T%2F%3Fmibextid%3DLQQJ4d", "_blank");
  }}
  aria-label="Facebook"
>
    <i className="fab fa-facebook-f"></i>
</Link>

<Link to="https://www.instagram.com/rootsappofficial/" 
  onClick={(e) => {
      e.preventDefault(); // Prevent normal navigation
      window.open("https://www.instagram.com/rootsappofficial/", "_blank");
  }}
  aria-label="Instagram"
>
    <i className="fab fa-instagram"></i>
</Link>

              {/* <Link to="#" aria-label="Twitter">
                <i className="fab fa-twitter"></i>
              </Link>
              <Link to="#" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in"></i>
              </Link> */}
              {/* <Link to="https://www.instagram.com/rootsappofficial/" aria-label="Instagram">
                <i className="fab fa-instagram"></i>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="row footer_list">
          <div className="col col_3">
            <h3 className="tit_24">Explore</h3>
            <ul className="contect_li">
              <li><Link to={`${devurl}/index.php`}>Home</Link></li>
              <li><Link to={`${devurl}/aboutus.php`}>About Us</Link></li>
              <li><Link to="/">Services</Link></li>
              <li><Link to="/">Blog</Link></li>
              <li><Link to={`${`${devurl}/contactus.php`}`}>Contact Us</Link></li>
            </ul>
          </div>
          <div className="col col_3">
            <h3 className="tit_24">Utility Pages</h3>
            <ul className="contect_li">
              <li><Link to="/">FAQ</Link></li>
              <li><Link to={`${devurl}/privacy-policy.php`}>Privacy Policy</Link></li>
              <li><Link to="/">Terms of Use</Link></li>
            </ul>
          </div>
          <div className="col col_3">
            <h3 className="tit_24">Keep in Touch</h3>
            <ul className="contect_li contact_address">
              <li>
                <p className="me-2"><b>Address : </b></p>
                <p className="text-wrap">7607 E Harry St Wichita, KS 67207</p>
              </li>
              <li>
                <p className="me-2"><b>Mail : </b></p>
                <p className="text-wrap"><Link className="mt_0" to="mailto:info@rootsapp.co">info@rootsapp.co</Link></p>
              </li>
              <li>
                <p className="me-2"><b>Phone : </b></p>
                <p className="text-wrap"><Link className="mt_0 mb-1" to="tel:+13166845122">+1 316-684-5122</Link></p>
              </li>
            </ul>
          </div>
        </div>
        <div className="copy_write">
            <p>© Copyright 2024 by Rootsapp. All rights reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
