import React, { useState,memo } from 'react';
import { Modal } from 'react-bootstrap';
import accounttype from '../images/Account-type.svg';
import Select from 'react-select';
import { guestapi, stylistapi } from '../commonjs/url';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../commonjs/axiosinstand';
import { handleApiError, process_params } from '../commonjs/commonfunctions';
const stylistapiurl = stylistapi();
const guestapiurl = guestapi();
const Stylistmodal = memo(({show,setIsLoading4,handleclose,showErrorToast}) => {
  const [stylishselect,setStylishselect] = useState('Guest');
  const navigate = useNavigate();
  const [error422,setError422] = useState('');
  const stylishoptions = [
    { value: 'Guest', label: 'Guest' },
  { value: 'Stylist', label: 'Stylist' },
  ]
  const customStyles1 = (hasValue) => ({
    control: (provided,state) => ({
      ...provided,
      borderColor: hasValue ? 'black' : '#DCDCDC',
      height:'40px',
      fontSize:'14px',
      width:'100%',
      borderRadius:'10px',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        borderColor: hasValue ? 'black' : '#DCDCDC',
      },
    }),placeholder: (provided) => ({
      ...provided,
      // color: '#ABABAB',
      width: '100%',
      fontSize:'12px',
      fontWeight:'500',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: hasValue ? 'black' : '#DCDCDC',
      svg: {
        fill: hasValue ? 'black' : '#B9B9B9',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
      container:(provided) => ({
        ...provided,
        marginBottom:'20px'
      })
  });
  const handlechange = (selectedOption) => {
     setStylishselect(selectedOption.value);
     setError422('');
  }

  
  const save = async() => {
    let url = stylishselect === 'Guest' ? guestapiurl : stylistapiurl;
   try{
    setIsLoading4(true);
    const response = await axiosInstance.post(url);
    if(response.status===200){
      const params = process_params();
      if (params.redirecturl) {
        window.location.href = params.redirecturl
          // redirectToWithAuthUser(redirectUrl, X_AuthUser);
      } else {
          // makeAjaxCall();
          navigate(`/success`);
      }
      handleclose();
      setIsLoading4(false);
    }
   }catch(error){
     handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
   }
  }
  return (
    <>
     <Modal show={show} centered>
     <Modal.Body style={{ margin: '0', padding: '0' }}>
      <div className='modal-flex'>
        <img src={accounttype} alt='account' className='stylisgimg'></img>
        <h6 className='stylishh6'>Choose your account type</h6>
        <p className='stylishp'>Choose 'Guest' for basic features or 'Stylist' for professional tools and resources.</p>
        <div className='selectdiv'>
        <Select  options={stylishoptions}  value={stylishoptions.find(option => option.value === stylishselect)}   isSearchable={true}  onChange={handlechange} styles={customStyles1(Boolean(stylishselect))} />
        {error422 && (<div className='errors'>{error422}</div>)}
        <button className='selectdiv_button' onClick={save}>Save</button>
        </div>
      </div> 
     </Modal.Body>
     </Modal>   
    </>
  )
})

export default Stylistmodal;